import React, { useEffect } from 'react';
import Layout from '../../components/layouts/project';

const PlayerLoadsProject = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://play.vidyard.com/embed/v4.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout
      title="Real-Time Geovisualization"
      description="A real-time visualization of where videos are being loaded around the world."
    >
      <div>
        <h1 className="my-12 mx-8 text-center">Real-Time Geovisualization</h1>
        <div className="demo py-12 overflow-x-auto bg-gray-900">
          <div className="video-plays">
            <img
              style={{
                width: '100%',
                margin: 'auto',
                display: 'block',
              }}
              className="vidyard-player-embed"
              src="https://play.vidyard.com/qKq6DNfG3URuRvnN3HnaLT.jpg"
              data-uuid="qKq6DNfG3URuRvnN3HnaLT"
              data-v="4"
              data-type="inline"
            />
          </div>
        </div>

        <div className="details m-auto py-12 px-8 max-w-2xl">
          <h2>Details</h2>
          <p>
            This visualization was built as part of an internal hackathon to
            showcase where videos hosted on Vidyard&rsquo;s platform were being
            loaded in real-time. The sped-up recording above was taken on the
            morning of December 16, 2018 (EST).
          </p>
          <p>The project consists of three main parts:</p>
          <ul>
            <li>
              A worker reads from an existing AWS Kinesis stream and emits data
              to the server for further processing. For context, Vidyard&rsquo;s
              video player regularly sends events to an ingestion server which
              are then put onto various Kinesis streams for other microservices
              to use.
            </li>
            <li>
              A backend server which augments video load data with geolocation
              information from Maxmind and sends it to all connected clients
              using{' '}
              <a href="https://github.com/socketio/socket.io">Socket.IO</a>.
            </li>
            <li>
              The visualization itself which reads data from a socket and
              renders it on a map (generated using{' '}
              <a href="https://github.com/markmarkoh/datamaps">DataMaps</a>).
              Two additional projections (one for Canada and one for the United
              States) were included to provide additional clarity for those
              countries.
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default PlayerLoadsProject;
